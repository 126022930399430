var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        _vm._l(_vm.relativeForms, function (value, key, index) {
          return _c(
            "el-tab-pane",
            { key: index, attrs: { label: value.name } },
            [
              value.auth.currentInsertFillAuth
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "default" },
                      on: {
                        click: function ($event) {
                          return _vm.addFillItem(
                            value.formId,
                            value.relativeInfo
                          )
                        },
                      },
                    },
                    [_vm._v("新增数据")]
                  )
                : _vm._e(),
              _c("fill-select-table-view", {
                attrs: {
                  fields: value.fields.fieldList,
                  datas: _vm.fillDatas(value),
                  isAdmin: value.auth.currentQueryFillListAuth,
                  inFillSelect: false,
                  viewItem: function (row, index) {
                    _vm.viewFillItem(row, index, value)
                  },
                  editItem: function (row, index) {
                    _vm.editFillItem(row, index, value)
                  },
                  removeItem: function (row, index) {
                    _vm.deleteFillItem(row, index, value)
                  },
                  onHandlePreviewImg: _vm.onHandlePreviewImg,
                },
              }),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增数据",
            visible: _vm.addForm.showAddNew,
            width: _vm.isMobile ? "350px" : "1220px",
            "before-close": _vm.closeNewData,
            "append-to-body": true,
            "modal-append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.addForm, "showAddNew", $event)
            },
          },
        },
        [
          [
            _c("v-form-render", {
              ref: "vformRender",
              attrs: {
                formJson: _vm.addForm.formJson,
                formData: _vm.addForm.formData,
                optionData: _vm.formOptionData,
                previewState: false,
              },
              scopedSlots: _vm._u([
                {
                  key: "myVxeTable",
                  fn: function (ref) {
                    var fields = ref.fields
                    var datas = ref.datas
                    var isAdmin = ref.isAdmin
                    var viewItem = ref.viewItem
                    var removeItem = ref.removeItem
                    var disabled = ref.disabled
                    return [
                      _c("fill-select-table-view", {
                        attrs: {
                          fields: fields,
                          datas: datas,
                          isAdmin: isAdmin,
                          viewItem: viewItem,
                          removeItem: removeItem,
                          onHandlePreviewImg: _vm.onHandlePreviewImg,
                          disabled: disabled,
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("div", { staticClass: "footer-container" }, [
                _c("div"),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: { click: _vm.saveNewData },
                      },
                      [_vm._v("确 定")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: { click: _vm.closeNewData },
                      },
                      [_vm._v("关 闭")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        2
      ),
      _c("fillSelector", { ref: "fillSelector" }),
      _c("VFormViewer", {
        ref: "dlgvFormViewer",
        on: { "on-save": _vm.onHandleEditRefreshData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }