var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.isShow,
            top: "2vh",
            fullscreen: !!_vm.previewFileUrl,
            width: _vm.isMobile ? "98%" : "1100px",
            "custom-class": "vFormViewerDialog",
            "destroy-on-close": true,
            "append-to-body": "",
            "close-on-click-modal": false,
            "before-close": _vm.close,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShow = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "slot-title" },
                    [
                      _c("div", [_vm._v(_vm._s(_vm.title || "表单详情"))]),
                      _vm.fillInfo
                        ? _c(
                            "div",
                            {
                              staticClass: "fill-info",
                              staticStyle: { "align-items": "flex-end" },
                            },
                            [
                              _vm.fillInfo.fillUserName
                                ? _c("div", { staticClass: "fill-info-user" }, [
                                    _c(
                                      "span",
                                      { staticClass: "user-card" },
                                      [
                                        _c("span", {
                                          staticClass: "user-card-avatar",
                                          style: {
                                            backgroundImage:
                                              "url('" +
                                              _vm.fillInfo.fillUserAvatarUrl +
                                              "?x-oss-process=image/resize,m_fixed,h_100,w_100')",
                                          },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.fillInfo.fillUserName) +
                                            " "
                                        ),
                                        _vm.login && _vm.isFormCreator
                                          ? _c("el-button", {
                                              attrs: {
                                                type: "text",
                                                icon: "el-icon-edit-outline",
                                              },
                                              on: { click: _vm.editFillUser },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.fillInfo.status || _vm.fillInfo.auditStatus
                                ? _c(
                                    "div",
                                    { staticStyle: { "margin-right": "10px" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "fill-info-text",
                                          staticStyle: {
                                            display: "inline-flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _vm._v("当前状态："),
                                          _c("StatusCode", {
                                            attrs: {
                                              statusCode:
                                                _vm.fillInfo.row.status,
                                              row: _vm.fillInfo.row,
                                              fillStatusCode:
                                                _vm.fillInfo.row.fillStatus,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "fill-info-text" },
                                        [
                                          _vm._v("审核意见："),
                                          _c(
                                            "a",
                                            {
                                              staticStyle: { color: "#3476f0" },
                                              on: { click: _vm.showAuditLog },
                                            },
                                            [_vm._v("点击查看")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.fillInfo.createTime || _vm.fillInfo.updateTime
                                ? _c("div", [
                                    _vm.fillInfo.createTime
                                      ? _c(
                                          "div",
                                          { staticClass: "fill-info-text" },
                                          [
                                            _vm._v(
                                              "填报时间：" +
                                                _vm._s(_vm.fillInfo.createTime)
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.fillInfo.updateTime
                                      ? _c(
                                          "div",
                                          { staticClass: "fill-info-text" },
                                          [
                                            _vm._v(
                                              "最近更新：" +
                                                _vm._s(
                                                  _vm.fillInfo.updateTime ||
                                                    _vm.fillInfo.createTime
                                                )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _vm.isEdit && _vm.isShowAuditButton
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "right",
                                        "margin-left": "30px",
                                      },
                                    },
                                    [
                                      _vm.allowSave
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "small",
                                                icon: "el-icon-document-checked",
                                              },
                                              on: { click: _vm.save },
                                            },
                                            [_vm._v("暂存")]
                                          )
                                        : _vm._e(),
                                      _vm.isShowAuditButton
                                        ? [
                                            _vm.allowSave
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "primary",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: _vm.saveAndAudit,
                                                    },
                                                  },
                                                  [_vm._v("审核")]
                                                )
                                              : _vm._e(),
                                            !_vm.allowSave
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "primary",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: _vm.onlyAudit,
                                                    },
                                                  },
                                                  [_vm._v("审核")]
                                                )
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]
                          )
                        : !_vm.isEdit &&
                          !_vm.isView &&
                          _vm.formDetail.isBatchFill
                        ? [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-upload",
                                  size: "mini",
                                },
                                on: { click: _vm.onHandleImportClick },
                              },
                              [_vm._v("批量填报")]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              {
                staticClass: "left",
                class: [_vm.previewFileUrl ? "" : "free"],
              },
              [
                _vm.formDetail && _vm.formDetail.fillAnony
                  ? _c("el-alert", {
                      attrs: {
                        title:
                          "已开启匿名提交功能，您的个人信息将不会被管理员看到",
                        "show-icon": "",
                        type: "warning",
                        closable: false,
                      },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticStyle: {
                      height: "100%",
                      width: "100%",
                      position: "relative",
                      display: "inline-flex",
                      overflow: "hidden",
                      "padding-right": "10px",
                      "box-sizing": "border-box",
                    },
                  },
                  [
                    _vm.isShow
                      ? _c(
                          "splitpanes",
                          {
                            staticClass: "default-theme",
                            staticStyle: { height: "100%" },
                            attrs: { vertical: "" },
                            on: {
                              resize: _vm.startSplitPanel,
                              resized: _vm.saveSplitPanel,
                            },
                          },
                          [
                            _vm.previewFileUrl
                              ? _c(
                                  "pane",
                                  {
                                    attrs: {
                                      size: _vm.splitViewSize,
                                      minSize: 30,
                                      maxSize: 70,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                          position: "relative",
                                          "background-color": "white",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass:
                                                  "close-preview-window",
                                                attrs: { type: "default" },
                                                on: {
                                                  click:
                                                    _vm.openPreviewInNewWindow,
                                                },
                                              },
                                              [_vm._v("新窗口打开")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass:
                                                  "close-preview-button",
                                                attrs: { type: "danger" },
                                                on: { click: _vm.closePreview },
                                              },
                                              [_vm._v("关闭预览")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("iframe", {
                                          staticStyle: {
                                            width: "100%",
                                            height: "100%",
                                            "pointer-events": "none",
                                            border: "none",
                                            position: "absolute",
                                            top: "0px",
                                            right: "5px",
                                            bottom: "0px",
                                          },
                                          style: {
                                            "pointer-events":
                                              _vm.isSplitDragging
                                                ? "none"
                                                : "unset",
                                          },
                                          attrs: { src: _vm.previewFileUrl },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c("pane", [
                              _c(
                                "div",
                                {
                                  ref: "vformRenderScroller",
                                  staticClass: "left-scroll",
                                  on: { scroll: _vm.onVFormRenderScroll },
                                },
                                [
                                  _c("v-form-render", {
                                    ref: "vformRender",
                                    staticStyle: { "margin-bottom": "50px" },
                                    attrs: {
                                      formJson: {},
                                      formData: {},
                                      optionData: _vm.optionData,
                                      previewState: false,
                                    },
                                    on: { "preview-file": _vm.showPreviewFile },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "myVxeTable",
                                          fn: function (ref) {
                                            var fields = ref.fields
                                            var datas = ref.datas
                                            var isAdmin = ref.isAdmin
                                            var field = ref.field
                                            var fieldRef = ref.fieldRef
                                            var viewItem = ref.viewItem
                                            var removeItem = ref.removeItem
                                            var disabled = ref.disabled
                                            var enableSearch = ref.enableSearch
                                            if (enableSearch === void 0)
                                              enableSearch = true
                                            var enableFilter = ref.enableFilter
                                            if (enableFilter === void 0)
                                              enableFilter = true
                                            return [
                                              _c("fill-select-table-view", {
                                                attrs: {
                                                  fields: fields,
                                                  datas: datas,
                                                  field: field,
                                                  fieldRef: fieldRef,
                                                  isAdmin: isAdmin,
                                                  viewItem: viewItem,
                                                  removeItem: removeItem,
                                                  onHandlePreviewImg:
                                                    _vm.onHandlePreviewImg,
                                                  formOptionData:
                                                    _vm.optionData,
                                                  disabled: disabled,
                                                  enableSearch: enableSearch,
                                                  enableFilter: enableFilter,
                                                  formDetail: _vm.formDetail,
                                                  formData: _vm.formData,
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1990772953
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.isEdit && !_vm.isView && !_vm.isShowAuditButton
                  ? _c(
                      "div",
                      {
                        staticClass: "btn-commit-wrapper",
                        class: { "btn-commit-shadow": _vm.showShadowBox },
                        staticStyle: { "text-align": "center" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "btn-commit",
                            attrs: { type: "primary", size: "medium" },
                            on: { click: _vm.save },
                          },
                          [_vm._v("保 存")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isEdit && !_vm.isView
                  ? _c(
                      "div",
                      {
                        staticClass: "btn-commit-wrapper",
                        class: { "btn-commit-shadow": _vm.showShadowBox },
                        staticStyle: { "text-align": "center" },
                      },
                      [
                        !!this.draftFillId
                          ? [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content:
                                      "再次点击存为草稿，会覆盖之前的草稿记录",
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "inline-block",
                                        "margin-right": "10px",
                                      },
                                      on: { click: _vm.saveDraftAndClose },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "default",
                                            size: "medium",
                                          },
                                        },
                                        [_vm._v(" 存草稿并关闭 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          : [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "default", size: "medium" },
                                  on: { click: _vm.saveDraftAndClose },
                                },
                                [_vm._v(" 存草稿并关闭 ")]
                              ),
                            ],
                        _c(
                          "el-button",
                          {
                            staticClass: "btn-commit",
                            attrs: { type: "primary", size: "medium" },
                            on: { click: _vm.saveAdd },
                          },
                          [_vm._v("提 交")]
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.login && _vm.actionExists("A7")
              ? _c(
                  "div",
                  {
                    staticClass: "right",
                    class: [_vm.showLog ? "" : "shrink"],
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "expand-button",
                        on: {
                          click: function ($event) {
                            _vm.showLog = !_vm.showLog
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "myRightArrow" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          height: "100%",
                          overflow: "hidden",
                        },
                      },
                      [
                        _c("div", { staticClass: "log-title" }, [
                          _vm._v("日志："),
                        ]),
                        _c(
                          "div",
                          { staticClass: "logview-container" },
                          _vm._l(_vm.logs, function (log, index) {
                            return _c("logView", {
                              key: index,
                              attrs: { item: log, formJson: _vm.formJson },
                              on: { "view-data": _vm.viewData },
                            })
                          }),
                          1
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _c("vFormInsideViewer", {
            ref: "dlgvFormViewer",
            attrs: { formDetail: _vm.formDetail },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看变更数据",
            visible: _vm.isShowLogChangeDataView,
            width: "1000px",
            "destroy-on-close": true,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isShowLogChangeDataView = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-alert", {
                    attrs: {
                      type: "warning",
                      title: "原数据",
                      closable: false,
                    },
                  }),
                  _c("v-form-render", {
                    ref: "vformChangeOld",
                    attrs: {
                      formJson: _vm.oldForm.formJson,
                      formData: _vm.oldForm.formData,
                      optionData: _vm.optionData,
                      previewState: false,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "myVxeTable",
                        fn: function (ref) {
                          var fields = ref.fields
                          var datas = ref.datas
                          var isAdmin = ref.isAdmin
                          var viewItem = ref.viewItem
                          var removeItem = ref.removeItem
                          return [
                            _c("fill-select-table-view", {
                              attrs: {
                                fields: fields,
                                datas: datas,
                                isAdmin: isAdmin,
                                viewItem: viewItem,
                                removeItem: removeItem,
                                onHandlePreviewImg: _vm.onHandlePreviewImg,
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-alert", {
                    attrs: {
                      type: "success",
                      title: "新数据",
                      closable: false,
                    },
                  }),
                  _c("v-form-render", {
                    ref: "vformChange",
                    attrs: {
                      formJson: _vm.newForm.formJson,
                      formData: _vm.newForm.formData,
                      optionData: _vm.optionData,
                      previewState: false,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "myVxeTable",
                        fn: function (ref) {
                          var fields = ref.fields
                          var datas = ref.datas
                          var isAdmin = ref.isAdmin
                          var viewItem = ref.viewItem
                          var removeItem = ref.removeItem
                          return [
                            _c("fill-select-table-view", {
                              attrs: {
                                fields: fields,
                                datas: datas,
                                isAdmin: isAdmin,
                                viewItem: viewItem,
                                removeItem: removeItem,
                                onHandlePreviewImg: _vm.onHandlePreviewImg,
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "-30px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.isShowLogChangeDataView = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.fillSuccessShow,
            width: "430px",
            "custom-class": "el-dialog-hide-header",
            "destroy-on-close": true,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.fillSuccessShow = $event
            },
            close: _vm.onCloseFillSuccessPop,
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "-webkit-flex",
                "flex-direction": "column",
              },
            },
            [
              _c(
                "h1",
                {
                  staticStyle: {
                    display: "-webkit-flex",
                    "justify-content": "center",
                    "align-items": "center",
                    "font-size": "20px",
                    margin: "-20px 0 0",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-check",
                    staticStyle: {
                      color: "#67C23A",
                      "font-size": "40px",
                      "margin-right": "10px",
                    },
                  }),
                  _vm._v("提交成功！"),
                ]
              ),
              _vm.customTip.commitTipShow == 0 && !_vm.isLocalDeploy
                ? [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          width: "100%",
                          "text-align": "center",
                          "font-size": "14px",
                          margin: "0",
                          "line-height": "30px",
                        },
                      },
                      [
                        _vm._v(
                          "若希望及时接受相关通知，可关注我们的公众号“鲸鱼填报”"
                        ),
                      ]
                    ),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          width: "100%",
                          "text-align": "center",
                          "font-size": "14px",
                          margin: "0",
                          "line-height": "30px",
                        },
                      },
                      [
                        _vm._v(
                          "例如可实时接收审核状态变更提醒、管理员定向提醒通知等。"
                        ),
                      ]
                    ),
                    _c("img", {
                      staticStyle: {
                        width: "200px",
                        height: "200px",
                        margin: "-5px auto -5px",
                      },
                      attrs: {
                        src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/gongzhonghao.jpeg",
                      },
                    }),
                  ]
                : [
                    _c("div", {
                      staticStyle: {
                        "max-height": "450px",
                        "overflow-y": "auto",
                      },
                      domProps: {
                        innerHTML: _vm._s(_vm.customTip.commitTipContent),
                      },
                    }),
                  ],
              _vm.formDetail.pay
                ? [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          width: "100%",
                          "text-align": "center",
                          "font-size": "14px",
                          margin: "10px auto 20px",
                          "line-height": "20px",
                        },
                      },
                      [
                        _vm._v(
                          "系统查询到本表单已开启支付功能。选择“立即支付”，进入支付页面。选择“暂不支付”，后续可在填报列表“支付”。"
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "pay-footer",
                        staticStyle: {
                          display: "-webkit-flex",
                          "justify-content": "space-around",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          { on: { click: _vm.onCancelPayFillClick } },
                          [_vm._v("暂不支付")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.onConfirmPayFillClick },
                          },
                          [_vm._v("立即支付")]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.subformTitle,
            visible: _vm.showSubformDialog,
            width: "1000px",
            "destroy-on-close": true,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSubformDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "myVxeTableContainer",
              staticStyle: { height: "600px" },
            },
            [
              _c(
                "vxe-table",
                _vm._b(
                  {
                    ref: "xSubformGrid",
                    attrs: { id: "toolbar_demo_2", data: _vm.subData },
                  },
                  "vxe-table",
                  _vm.tableConfig,
                  false
                ),
                [
                  this.tableConfig.showRowNumber
                    ? _c("vxe-column", {
                        attrs: { type: "seq", title: "序号", width: "60" },
                      })
                    : _vm._e(),
                  _vm._l(_vm.subColumns, function (col, idx) {
                    return [
                      !col.slots
                        ? _c(
                            "vxe-column",
                            _vm._b(
                              {
                                key: idx,
                                attrs: {
                                  filters: col.filters,
                                  "filter-render": col.filterRender,
                                  params: col,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "header",
                                      fn: function (ref) {
                                        var $table = ref.$table
                                        var column = ref.column
                                        return [
                                          _c(
                                            "span",
                                            {
                                              attrs: {
                                                title: JSON.stringify(col),
                                              },
                                            },
                                            [_vm._v(_vm._s(column.title))]
                                          ),
                                          column.sortable
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "custom-sort",
                                                  class: {
                                                    "is-order": column.order,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.headerCellClickEvent(
                                                        {
                                                          $table: $table,
                                                          column: column,
                                                        }
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    class: [
                                                      column.order
                                                        ? "el-icon-sort-" +
                                                          (column.order ===
                                                          "asc"
                                                            ? "up"
                                                            : "down")
                                                        : "el-icon-sort",
                                                    ],
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              "vxe-column",
                              col,
                              false
                            )
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "-30px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showSubformDialog = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("userSelect", {
        ref: "userSelect",
        attrs: {
          callback: _vm.userSelectCallback,
          selectSingle: true,
          formDetail: _vm.formDetail,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.paySuccessShow,
            width: "430px",
            "custom-class": "el-dialog-hide-header",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.paySuccessShow = $event
            },
            close: _vm.onClosePaySuccessPop,
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "-webkit-flex",
                "flex-direction": "column",
              },
            },
            [
              _c(
                "h1",
                {
                  staticStyle: {
                    display: "-webkit-flex",
                    "justify-content": "center",
                    "align-items": "center",
                    "font-size": "20px",
                    margin: "-20px 0 0",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-check",
                    staticStyle: {
                      color: "#67C23A",
                      "font-size": "40px",
                      "margin-right": "10px",
                    },
                  }),
                  _vm._v("支付成功！ "),
                ]
              ),
              _vm.customTip.payTipShow == 0 && !_vm.isLocalDeploy
                ? [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          width: "100%",
                          "text-align": "center",
                          "font-size": "14px",
                          margin: "0",
                          "line-height": "30px",
                        },
                      },
                      [
                        _vm._v(
                          " 若希望及时接受相关通知，可关注我们的公众号“鲸鱼填报” "
                        ),
                      ]
                    ),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          width: "100%",
                          "text-align": "center",
                          "font-size": "14px",
                          margin: "0",
                          "line-height": "30px",
                        },
                      },
                      [
                        _vm._v(
                          " 例如可实时接收审核状态变更提醒、管理员定向提醒通知等。 "
                        ),
                      ]
                    ),
                    _c("img", {
                      staticStyle: {
                        width: "200px",
                        height: "200px",
                        margin: "-5px auto -5px",
                      },
                      attrs: {
                        src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/gongzhonghao.jpeg",
                      },
                    }),
                  ]
                : [
                    _c("div", {
                      staticStyle: {
                        "max-height": "450px",
                        "overflow-y": "auto",
                      },
                      domProps: {
                        innerHTML: _vm._s(_vm.customTip.payTipContent),
                      },
                    }),
                  ],
            ],
            2
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "数据填报支付",
            visible: _vm.formPayOpen,
            "custom-class": "el-dialog-hide-header",
            width: "400px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.formPayOpen = $event
            },
            close: _vm.onClosePay,
          },
        },
        [
          _c("h3", { staticStyle: { width: "100%", "text-align": "center" } }, [
            _vm._v(" 本次填报支付" + _vm._s(_vm.orderPrice / 100) + "元 "),
          ]),
          _c("div", {
            ref: "qrCodeUrl",
            staticClass: "qrCodeCon",
            staticStyle: {
              "text-align": "center",
              margin: "auto",
              width: "200px",
            },
            attrs: { id: "qrCode" },
          }),
          _c("h3", { staticStyle: { width: "100%", "text-align": "center" } }, [
            _vm._v("微信扫一扫付款"),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.draftSuccessShow,
            width: "430px",
            "append-to-body": "",
            "custom-class": "el-dialog-hide-header",
          },
          on: {
            "update:visible": function ($event) {
              _vm.draftSuccessShow = $event
            },
            close: _vm.saveDraftSuccess,
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "-webkit-flex",
                "flex-direction": "column",
              },
            },
            [
              _c(
                "h1",
                {
                  staticStyle: {
                    display: "-webkit-flex",
                    "justify-content": "center",
                    "align-items": "center",
                    "font-size": "20px",
                    margin: "-20px 0 0",
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-check",
                    staticStyle: {
                      color: "#67C23A",
                      "font-size": "40px",
                      "margin-right": "10px",
                    },
                  }),
                  _vm._v("草稿已保存 "),
                ]
              ),
              _c(
                "p",
                {
                  staticStyle: {
                    width: "100%",
                    "text-align": "center",
                    "font-size": "14px",
                    margin: "0",
                    "line-height": "30px",
                  },
                },
                [_vm._v("请查阅个人填报列表右上角的草稿箱，并完成草稿的提交。")]
              ),
              _c("img", {
                attrs: {
                  src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/save-draft-tip-0704.png",
                },
              }),
            ]
          ),
        ]
      ),
      _vm.showAddNew
        ? _c("VFormViewer", {
            ref: "dlgvNewFormViewer",
            attrs: { quite: true, formAuth: _vm.formAuth },
            on: { "on-save": _vm.onHandleDataChooseAddData },
          })
        : _vm._e(),
      _c("fillSelector", {
        ref: "fillSelector",
        attrs: { optionData: _vm.optionData },
      }),
      _c("auditLogDialog", { ref: "auditLogRef" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }