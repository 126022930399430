<template>
  <div class="log-item" :class="[item.typeStr]">
    <div class="log-title">
      <div class="log-time"><span style="display:inline-block;width:90px">{{moment(item.time).format('MM-DD HH:mm')}}</span> {{item.userName}} </div>
      <div class="log-type">{{item.typeStr}}</div>
    </div>
    <div class="log-text">
      <!-- <div class="log-user">{{item.user}}</div> -->
      <template v-if="item.typeStr=='提交内容'">
        <div class="log-content">填报了表单</div>
      </template>
      <template v-if="item.typeStr=='修改内容'">
        <div class="log-content" v-for="(change,index) in item.data" :key="index">
          <!-- {{change}} -->
          <template v-if="showChangeLogDialog(change.fieldType)">
            <span style="font-weight:bold">{{change.label}}</span>
            <span class="data-view-btn" @click="viewData(change)">查看变更</span>
          </template>
          <template v-else-if="change.label">
            <template v-if="isRenderField(change.fieldType)">
              <div>
              <span style="font-weight:bold">{{change.label}}</span>
              由【<fillSelectCardComponentRender v-bind="bindRender(change.fieldType,change.oldValue)"></fillSelectCardComponentRender>】
              变更为【<fillSelectCardComponentRender v-bind="bindRender(change.fieldType,change.value)"></fillSelectCardComponentRender>】
              </div>
            </template>
            <template v-else>
              <span style="font-weight:bold">{{change.label}}</span>由【{{change.oldValue||'空'}} 】变更为【{{change.value||'空'}}】
            </template>
          </template>
        </div>
        <div class="log-content"  v-if="item.data.length==0">
          <span>重新保存表单，但未更改任何数据</span>
        </div>
        <!-- {{formJson}} -->
      </template>
      <template v-if="item.typeStr=='完成支付'">
        <div class="log-content">完成填报支付</div>
      </template>
      <template v-if="item.typeStr=='支付已确认'">
        <div class="log-content">确认用户完成了支付</div>
      </template>
      <template v-if="item.typeStr=='一级审核-通过'">
        <div class="log-content" v-if="item.status > 6 && item.status != 14">完成一级审核，已提交二级审核</div>
        <div class="log-content" v-else>完成一级审核</div>
      </template>
      <template v-if="item.typeStr=='二级审核-通过'">
        <div class="log-content" v-if="item.status > 10 && item.status != 14">完成二级审核，已提交三级审核</div>
        <div class="log-content" v-else>完成二级审核</div>
      </template>
      <template v-if="item.typeStr=='三级审核-通过'">
        <div class="log-content">审核通过</div>
      </template>
      <template v-if="item.typeStr=='一级审核-不通过'">
        <div class="log-content">一级审核不通过<span v-if="item.data&&item.data.length>0"><br>审批意见：{{item.data[0].text}}</span></div>
      </template>
      <template v-if="item.typeStr=='二级审核-不通过'">
        <div class="log-content">二级审核不通过<span v-if="item.data&&item.data.length>0"><br>审批意见：{{item.data[0].text}}</span></div>
      </template>
      <template v-if="item.typeStr=='一级审核-返回修改'">
        <div class="log-content">一级审核不通过，已退回修改<span v-if="item.data&&item.data.length>0"><br>审批意见：{{item.data[0].text}}</span></div>
      </template>
      <template v-if="item.typeStr=='二级审核-返回修改'">
        <div class="log-content">二级审核不通过，已退回修改<span v-if="item.data&&item.data.length>0"><br>审批意见：{{item.data[0].text}}</span></div>
      </template>
      <template v-if="item.typeStr=='三级审核-不通过'">
        <div class="log-content">三级审核-不通过<span v-if="item.data&&item.data.length>0"><br>审批意见：{{item.data[0].text}}</span></div>
      </template>
      <template v-if="item.typeStr=='三级审核-返回修改'">
        <div class="log-content">三级审核-返回修改<span v-if="item.data&&item.data.length>0"><br>审批意见：{{item.data[0].text}}</span></div>
      </template>
    </div>
  </div>
</template>
<script>
import vFormInsideViewer from './inside.vue'
import { renderProvider } from '@/utils/gridrender/cellRender';
import fillSelectCardComponentRender from '@/views/system/form/components/fillSelector/fill-select-widget-components/fill-select-card-component-render.vue';
export default {
  components: { vFormInsideViewer, fillSelectCardComponentRender },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    formJson: {
      type: Object
    }
  },
  data() {
    return {
      moment: require('moment'),
      renderProvider:renderProvider
    }
  },
  methods: {
    showChangeLogDialog(fieldType) {
      let type = ['sub-form','matrix-form', 'sign', 'file-upload', 'cascader','picture-upload', 'fill-select', 'picture-select'];
      return type.indexOf(fieldType) >= 0;
    },
    viewData(dataValue) {
      this.$emit("view-data", dataValue);
    },
    isRenderField(type){
      return !!renderProvider[type]
    },
    renderField(field,data) {
      let { renderDefault } = renderProvider[field.type]

      // select 需要判断一下renderDefault用哪个
      if (field.type == 'select') {
        renderDefault = renderProvider[field.filterType].renderDefault
      }

      return {
        widgetRender: renderDefault,
        field: field,
        row: {
          [field.name]: data
        } ,
        column: {
          ...field,
          property: field.name,
          params: field.options
        }
      }
    },
    bindRender(type,data){
      return this.renderField(this.formJson.fieldList.find(x=>x.type===type),data)
    }
  }
}
</script>
<style lang="scss" scoped>
.log-item {
  color: #555555;
  border-left: solid 1px #00c345;
  padding-left: 8px;
  line-height: 30px;
  box-sizing: border-box;
  position: relative;
  // margin-bottom:5px;
  padding-bottom: 20px;

  &.修改内容 {
    border-left-color: #fae52a;
    &:before {
      background-color: #fae52a;
    }
  }
  &.完成支付 {
    border-left-color: #ee44b5;
    &:before {
      background-color: #ee44b5;
    }
  }
  &.支付已确认 {
    border-left-color: #35b4d0;
    &:before {
      background-color: #35b4d0;
    }
  }
  &.一级审核-通过,
  &.二级审核-通过 {
    border-left-color: #46b011;
    &:before {
      background-color: #46b011;
    }
  }
  &.一级审核-不通过,
  &.二级审核-不通过,
  &.一级审核-返回修改,
  &.二级审核-返回修改 {
    border-left-color: #e78803;
    &:before {
      background-color: #e78803;
    }
  }

  &:before {
    position: absolute;
    content: '';
    width: 7px;
    height: 7px;
    top: 12px;
    left: -4px;
    border-radius: 50%;
    background-color: #00c345;
    display: inline-block;
  }
}
.log-title {
  width: 100%;
  display: inline-flex;
  align-items:center;
  justify-content: space-between;
}
.log-time {
  flex-grow:1;
  display: inline-block;
  text-align: left;
}
.log-type {
  width: 135px;
  display: inline-block;
  text-align: right;
}
.log-user {
  font-weight: bold;
  line-height: 20px;
}
.log-text {
  // line-height: 20px;
  background-color: #f0f0f0;
  // border:solid 1px #c8c8c8;
  box-sizing: border-box;
  .log-content {
    padding: 10px 5px;
  }
  .data-view-btn {
    margin-left: 10px;
    color: #409eff;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
.log-content {
  margin-top: 5px;
}

::v-deep .picture-view{
  width:30px!important;
  height:30px!important;
}

::v-deep .user-card-wrpper{
  display:inline-flex;
  align-items: center;
  .user-bind{
    font-size:12px;
    margin-right:10px;
  }
}
::v-deep .user-card {
  display: inline-flex;
  align-items: center;
  border-radius: 9px;
  // padding-right: 8px;
  // margin-right:4px;

  box-sizing: border-box;
  height: 24px;
  line-height: 24px;
  background-color: #e5e5e5;
  color: black;


  cursor: pointer;

  &-avatar {
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin-right: 3px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    background-color: #5b2bf6;
    background-size: cover;
    background-position: center center;
    color: white;
    position: relative;
  }
}


@import url('~@/assets/styles/filetype.css');
.file-info {
  box-sizing: border-box;
  &-thumb{
    width: 150px;
  }
  &-name {
    min-width: 150px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 5px;
  }
  &-extra {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }
  &-size {
    display: inline-block;
  }
  &-actions {
    display: inline-block;

    .svg-icon {
      padding:0px;
      font-size:20px;
      color: #9e9e9e;
      border-radius: 4px;
      cursor: pointer;
      border:solid 1px transparent;

      &:hover{
        color: #409eff;
        border:solid 1px #e5e5e5;
        background-color:#eeeeee;
      }
    }
  }
}

::v-deep .viewer-container .viewer-list {
  width: 80% !important;
}
</style>
