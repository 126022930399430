<template>

  <div>
    <el-dialog :visible.sync="isShow" top="2vh" :fullscreen="!!previewFileUrl" :width="isMobile? '98%':'1100px'" custom-class="vFormViewerDialog" :destroy-on-close="true" append-to-body :close-on-click-modal="false" :before-close="close">
      <template #title>
        <div class="slot-title">
          <div>{{title||'表单详情'}}</div>
          <div class="fill-info" v-if="fillInfo" style="align-items:flex-end">
            <!-- <div class="fill-info-user-title">填报人</div> -->
            <div class="fill-info-user" v-if="fillInfo.fillUserName">
              <span class="user-card">
                <span class="user-card-avatar" :style="{backgroundImage: 'url(\''+fillInfo.fillUserAvatarUrl+'?x-oss-process=image/resize,m_fixed,h_100,w_100\')'}"></span>
                {{fillInfo.fillUserName}}
                <el-button type="text" icon="el-icon-edit-outline" @click="editFillUser" v-if="login && isFormCreator"></el-button>
              </span>
            </div>
            <div style="margin-right:10px" v-if="fillInfo.status || fillInfo.auditStatus">
              <div class="fill-info-text" style="display:inline-flex;align-items:center;">当前状态：<StatusCode :statusCode="fillInfo.row.status" :row="fillInfo.row" :fillStatusCode="fillInfo.row.fillStatus"></StatusCode></div>
              <div class="fill-info-text">审核意见：<a style="color:#3476f0" @click="showAuditLog">点击查看</a></div>
            </div>
            <div v-if="fillInfo.createTime || fillInfo.updateTime">
              <div class="fill-info-text" v-if="fillInfo.createTime">填报时间：{{fillInfo.createTime}}</div>
              <div class="fill-info-text" v-if="fillInfo.updateTime">最近更新：{{fillInfo.updateTime || fillInfo.createTime}}</div>
            </div>
            <!-- 弹出填报--表单填报 -->
            <div v-if="isEdit && isShowAuditButton" style="text-align:right;margin-left:30px;">
              <el-button type="text" size="small" icon="el-icon-document-checked" v-if="allowSave" @click="save">暂存</el-button>
              <template v-if="isShowAuditButton">
                <el-button type="primary" size="small" @click="saveAndAudit" v-if="allowSave">审核</el-button>
                <el-button type="primary" size="small" @click="onlyAudit" v-if="!allowSave">审核</el-button>
              </template>
            </div>
          </div>
          <template v-else-if="!isEdit && !isView && formDetail.isBatchFill">
            <el-button type="primary" icon="el-icon-upload" size="mini" @click="onHandleImportClick">批量填报</el-button>
          </template>
        </div>
      </template>
      <div class="container">
        <div class="left" :class='[previewFileUrl?"":"free"]'>
          <el-alert v-if="formDetail && formDetail.fillAnony" title="已开启匿名提交功能，您的个人信息将不会被管理员看到" show-icon type="warning" :closable="false"></el-alert>
          <div style="height:100%;width:100%;position:relative;display:inline-flex;overflow:hidden;padding-right:10px;box-sizing:border-box;">
          <splitpanes class="default-theme" @resize="startSplitPanel" @resized="saveSplitPanel" vertical style="height: 100%" v-if="isShow">
            <pane :size="splitViewSize" :minSize="30" :maxSize="70" v-if="previewFileUrl">
              <div style="width:100%;height:100%;position:relative;background-color:white;">
                  <div><el-button type="default" class="close-preview-window" @click="openPreviewInNewWindow">新窗口打开</el-button></div>
                  <div><el-button type="danger" class="close-preview-button" @click="closePreview">关闭预览</el-button></div>
                  <iframe style="width:100%;height:100%;pointer-events:none;border:none;position:absolute;top:0px;right:5px;bottom:0px;" :style="{'pointer-events':isSplitDragging?'none':'unset'}" :src="previewFileUrl" ></iframe>
                </div>
             </pane>
              <pane>
              <div class="left-scroll" ref="vformRenderScroller" @scroll="onVFormRenderScroll">
                  <v-form-render ref="vformRender" :formJson="{}" :formData="{}" :optionData="optionData" :previewState="false" style="margin-bottom: 50px;"
                    @preview-file="showPreviewFile">
                    <template v-slot:myVxeTable="{fields,datas,isAdmin, field,fieldRef, viewItem,removeItem,disabled, enableSearch =true , enableFilter =true}">
                      <fill-select-table-view :fields="fields" :datas="datas" :field="field" :fieldRef="fieldRef" :isAdmin="isAdmin" :viewItem="viewItem" :removeItem="removeItem" :onHandlePreviewImg="onHandlePreviewImg" :formOptionData="optionData" :disabled="disabled" :enableSearch="enableSearch"
                                              :enableFilter="enableFilter" :formDetail="formDetail" :formData="formData"></fill-select-table-view>
                    </template>
                    <!-- <template v-slot:relatives="{relatives}">
                    <FillSelectBothSideRelative :relatives="relatives" :isAdmin="isAdmin" :viewItem="viewFormItem" :removeItem="removeFormItem" :onHandlePreviewImg="onHandlePreviewImg" :disabled="disabled" :formOptionData="optionData"></FillSelectBothSideRelative>
                  </template> -->
                  </v-form-render>
              </div>
             </pane>
          </splitpanes>
          </div>
          <!-- 编辑模式 -->
          <div v-if="isEdit && !isView && !isShowAuditButton" class="btn-commit-wrapper" :class="{'btn-commit-shadow': showShadowBox}" style="text-align:center">
            <!-- <el-button type="default" size="medium" class="btn-commit" @click="saveDraftAndClose" style="width:150px;box-sizing:border-box;">
              <span>
                <el-tooltip class="item" effect="light" placement="top-start">
                  <div slot="content" style="line-height:20px">
                    1.此操作只会将修改的信息暂时存到您电脑的缓存中，并未对数据做任何修改;<br />
                    2.下次您再次修改此记录时，会提示是否加载草稿：<br />&nbsp;&nbsp;选是，则会将您上次修改内容一并加载过来，如果选择否，则只会加载后台数据，并清空您的草稿;<br />
                    3.仅支持保存一条缓存记录。
                  </div>
                  <i class="el-icon-info" style="margin-right:5px;"></i>
                </el-tooltip>
                存草稿并关闭</span>
            </el-button> -->
            <el-button type="primary" size="medium" class="btn-commit" @click="save">保 存</el-button>
          </div>
          <!-- 新增模式 -->
          <div v-if="!isEdit && !isView" class="btn-commit-wrapper" :class="{'btn-commit-shadow': showShadowBox}" style="text-align:center">
            <template v-if="!!this.draftFillId">
              <el-tooltip content="再次点击存为草稿，会覆盖之前的草稿记录" placement="top-start">
                <!-- click事件写在button上，和tooltip冲突，仅能点击一次，因此写在div上 -->
                <div style="display:inline-block;margin-right:10px;" @click="saveDraftAndClose">
                  <el-button type="default" size="medium">
                    存草稿并关闭
                  </el-button>
                </div>
              </el-tooltip>
            </template>
            <template v-else>
              <el-button type="default" size="medium" @click="saveDraftAndClose">
                存草稿并关闭
              </el-button>
            </template>
            <el-button type="primary" size="medium" class="btn-commit" @click="saveAdd">提 交</el-button>
          </div>
        </div>
        <div class="right" :class="[showLog?'':'shrink']" v-if="login && actionExists('A7')">
          <div class="expand-button" @click="showLog=!showLog">
            <svg-icon icon-class="myRightArrow" />
          </div>
          <div style="width:100%;height: 100%;overflow:hidden;">
            <div class="log-title">日志：</div>
            <div class="logview-container">
              <logView v-for="(log,index) in logs" :key="index" :item="log" :formJson="formJson" @view-data="viewData"></logView>
            </div>
          </div>
        </div>
      </div>
      <vFormInsideViewer ref="dlgvFormViewer" :formDetail="formDetail"></vFormInsideViewer>
    </el-dialog>

    <el-dialog title="查看变更数据" :visible.sync="isShowLogChangeDataView" width="1000px" :destroy-on-close="true" append-to-body>
      <el-row :gutter="10">
        <el-col :span="12">
          <el-alert type="warning" title="原数据" :closable="false"></el-alert>
          <v-form-render ref="vformChangeOld" :formJson="oldForm.formJson" :formData="oldForm.formData" :optionData="optionData" :previewState="false">
            <template v-slot:myVxeTable="{fields,datas,isAdmin,viewItem,removeItem}">
              <fill-select-table-view :fields="fields" :datas="datas" :isAdmin="isAdmin" :viewItem="viewItem" :removeItem="removeItem" :onHandlePreviewImg="onHandlePreviewImg"></fill-select-table-view>
            </template>
          </v-form-render>
        </el-col>
        <el-col :span="12">
          <el-alert type="success" title="新数据" :closable="false"></el-alert>
          <v-form-render ref="vformChange" :formJson="newForm.formJson" :formData="newForm.formData" :optionData="optionData" :previewState="false">
            <template v-slot:myVxeTable="{fields,datas,isAdmin,viewItem,removeItem}">
              <fill-select-table-view :fields="fields" :datas="datas" :isAdmin="isAdmin" :viewItem="viewItem" :removeItem="removeItem" :onHandlePreviewImg="onHandlePreviewImg"></fill-select-table-view>
            </template>
          </v-form-render>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button type="primary" @click="isShowLogChangeDataView=false">关 闭</el-button>
      </div>
    </el-dialog>

    <!--数据查看，管理员批量导入他人填报-->
    <!--填报成功，公众号引导提示-->
    <el-dialog :visible.sync="fillSuccessShow" width="430px" custom-class="el-dialog-hide-header" :destroy-on-close="true" append-to-body @close="onCloseFillSuccessPop">
      <!-- <el-dialog :visible="true" width="430px" append-to-body @close="onCloseFillSuccessPop"> -->
      <div style="display: flex; display: -webkit-flex; flex-direction: column;">
        <h1 style="display: flex; display: -webkit-flex; justify-content: center; align-items: center;font-size: 20px; margin: -20px 0 0;"><i class="el-icon-check" style="color: #67C23A; font-size: 40px; margin-right: 10px;"></i>提交成功！</h1>
        <!-- 表单未开启支付，显示提交成功提示 -->
        <!-- 默认提交文字 -->
        <template v-if="customTip.commitTipShow==0 && !isLocalDeploy">
          <p style="width: 100%; text-align: center; font-size: 14px; margin: 0; line-height: 30px;">若希望及时接受相关通知，可关注我们的公众号“鲸鱼填报”</p>
          <p style="width: 100%; text-align: center; font-size: 14px; margin: 0; line-height: 30px">例如可实时接收审核状态变更提醒、管理员定向提醒通知等。</p>
          <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/gongzhonghao.jpeg" style="width: 200px; height: 200px; margin: -5px auto -5px;">
        </template>
        <!-- 自定义提交文字 -->
        <template v-else>
          <div v-html="customTip.commitTipContent" style="max-height:450px;overflow-y:auto;"></div>
        </template>
        <template v-if="formDetail.pay">
          <p style="width: 100%; text-align: center; font-size: 14px; margin: 10px auto 20px; line-height: 20px;">系统查询到本表单已开启支付功能。选择“立即支付”，进入支付页面。选择“暂不支付”，后续可在填报列表“支付”。</p>
          <div class="pay-footer" style="display: flex; display: -webkit-flex; justify-content: space-around;">
            <el-button @click="onCancelPayFillClick">暂不支付</el-button>
            <el-button type="primary" @click="onConfirmPayFillClick">立即支付</el-button>
          </div>
        </template>
      </div>
    </el-dialog>

    <!-- 子表单查看对话框 -->
    <el-dialog :title="subformTitle" :visible.sync="showSubformDialog" width="1000px" :destroy-on-close="true" append-to-body>
      <div style="height:600px;" class="myVxeTableContainer">
        <vxe-table ref="xSubformGrid" id="toolbar_demo_2" v-bind="tableConfig" :data="subData">
          <vxe-column type="seq" title="序号" width="60" v-if="this.tableConfig.showRowNumber"></vxe-column>
          <!-- <vxe-column title="" field="title" min-width="80" align="left" v-if="this.tableConfig.showTitleRow"></vxe-column> -->
          <template v-for="(col,idx) in subColumns">
            <vxe-column v-if="!col.slots" v-bind="col" :filters="col.filters" :filter-render="col.filterRender" :params="col" :key="idx">
              <template #header="{$table,column}">
                <span :title="JSON.stringify(col)">{{ column.title }}</span>
                <span class="custom-sort" :class="{'is-order': column.order}" v-if="column.sortable" @click="headerCellClickEvent({$table:$table,column:column})">
                  <i :class="[column.order  ? `el-icon-sort-${column.order==='asc' ? 'up' : 'down'}` : 'el-icon-sort']"></i>
                </span>
              </template>
            </vxe-column>
          </template>
        </vxe-table>
      </div>
      <div slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button type="primary" @click="showSubformDialog=false">关闭</el-button>
      </div>
    </el-dialog>

    <userSelect ref="userSelect" :callback="userSelectCallback" :selectSingle="true" :formDetail="formDetail"></userSelect>

    <!--支付成功，提示文字-->
    <el-dialog :visible.sync="paySuccessShow" width="430px" custom-class="el-dialog-hide-header" append-to-body @close="onClosePaySuccessPop">
      <!-- <el-dialog :visible="true" width="430px" append-to-body @close="onClosePaySuccessPop"> -->
      <div style="display: flex; display: -webkit-flex; flex-direction: column;">
        <h1 style="display: flex; display: -webkit-flex; justify-content: center; align-items: center;font-size: 20px; margin: -20px 0 0;">
          <i class="el-icon-check" style="color: #67C23A; font-size: 40px; margin-right: 10px;"></i>支付成功！
        </h1>
        <!-- 默认提交提交文字 -->
        <template v-if="customTip.payTipShow == 0 && !isLocalDeploy">
          <p style="width: 100%; text-align: center; font-size: 14px; margin: 0; line-height: 30px;">
            若希望及时接受相关通知，可关注我们的公众号“鲸鱼填报”
          </p>
          <p style="width: 100%; text-align: center; font-size: 14px; margin: 0; line-height: 30px">
            例如可实时接收审核状态变更提醒、管理员定向提醒通知等。
          </p>
          <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/gongzhonghao.jpeg" style="width: 200px; height: 200px; margin: -5px auto -5px;" />
        </template>
        <!-- 自定义提交文字 -->
        <template v-else>
          <div v-html="customTip.payTipContent" style="max-height:450px;overflow-y:auto;"></div>
        </template>
      </div>
    </el-dialog>
    <!--支付成功，提示文字-->
    <!--支付填报-->
    <el-dialog title="数据填报支付" :visible.sync="formPayOpen" custom-class="el-dialog-hide-header" width="400px" append-to-body @close="onClosePay">
      <h3 style="width: 100%; text-align: center">
        本次填报支付{{ orderPrice / 100 }}元
      </h3>
      <div class="qrCodeCon" ref="qrCodeUrl" style="text-align: center; margin: auto; width: 200px;" id="qrCode"></div>
      <h3 style="width: 100%; text-align: center">微信扫一扫付款</h3>
    </el-dialog>

    <el-dialog :visible.sync="draftSuccessShow" width="430px" append-to-body custom-class="el-dialog-hide-header" @close="saveDraftSuccess">
      <div style="display: flex; display: -webkit-flex; flex-direction: column;">
        <h1 style="display: flex; display: -webkit-flex; justify-content: center; align-items: center;font-size: 20px; margin: -20px 0 0;">
          <i class="el-icon-check" style="color: #67C23A; font-size: 40px; margin-right: 10px;"></i>草稿已保存
        </h1>

        <p style="width: 100%; text-align: center; font-size: 14px; margin: 0; line-height: 30px;">请查阅个人填报列表右上角的草稿箱，并完成草稿的提交。</p>
        <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/save-draft-tip-0704.png">
      </div>
    </el-dialog>

    <!-- <el-dialog title="新增数据" :visible.sync="showAddNew" :width="isMobile?'350px':'1220px'" :before-close="closeNewData" :append-to-body="true" :modal-append-to-body="true">
      <template>
        <v-form-render ref="vformNewRender" :formJson="formJson" :formData="formData" :disableAutoFill="true" :optionData="optionData" :previewState="false">
        </v-form-render>
      </template>
      <div slot="footer" class="dialog-footer">
        <div class="footer-container">
          <div>
          </div>
          <div>
            <el-button type="primary" size="small" @click="saveNewData">确 定</el-button>
            <el-button size="small" @click="closeNewData">关 闭</el-button>
          </div>
        </div>
      </div>
    </el-dialog> -->
    <VFormViewer ref="dlgvNewFormViewer" v-if="showAddNew" :quite="true" :formAuth="formAuth" @on-save="onHandleDataChooseAddData">
    </VFormViewer>
    <!-- 这个没用上 -->
    <fillSelector ref="fillSelector" :optionData="optionData"></fillSelector>
    <!-- <VFormViewer ref="dlgvFormViewer" @on-save="onHandleEditRefreshData" :onHandleImportClick="onHandleImportClick"></VFormViewer> -->
    <auditLogDialog ref="auditLogRef"></auditLogDialog>
  </div>
</template>
<script>
import vFormInsideViewer from './inside.vue'
import formViewerMixin from './formViewerMixin.js'
import logView from './logView.vue'
import { isMobile, deepClone } from '@/utils/index'
import { updateFillUser } from '@/api/system/form'
import filedColumnMap from '@/utils/gridHeader.js'

import userSelect from '@/views/system/mechan/components/user-select.vue'
import StatusCode from "../../components/statusCode.vue";

import fillSelector from "../fillSelector";
import FillSelectTableView from '@/views/system/form/components/fillSelector/tableView'
import FillSelectBothSideRelative from '@/views/system/form/components/fillSelector/bothSideRelatives'
import auditLogDialog from '../tables/auditLog'

import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

export default {
  name: 'vFormViewer',
  componentName: 'vFormViewer',
  components: { StatusCode, vFormInsideViewer,auditLogDialog, fillSelector, logView, userSelect, FillSelectTableView, FillSelectBothSideRelative, Splitpanes, Pane },
  mixins: [formViewerMixin],
  data () {
    return {
      optionData: {},
      showLog: false,
      isShowLogChangeDataView: false,
      isMobile: isMobile(),
      newForm: {
        formJson: {},
        formData: {}
      },
      oldForm: {
        formJson: {},
        formData: {}
      },
      loading: {
        submit: false
      },
      userSelectCallback: null,
      tableConfig: {
        border: true,
        align: "center",
        resizable: true,
        keepSource: true,
        showOverflow: true,
        loading: false,
        autoResize: true,
        height: "100%",
        highlightHoverRow: true,
        highlightCurrentRow: true,
        emptyText: "暂无数据",
        showHeaderOverflow: "tooltip",
        sortConfig: {
          multiple: true,
          showIcon: false,
          trigger: 'default',
          chronological: true,
          orders: ["desc", "asc", 'null'],
          defaultSort: { field: "id", order: "desc" },
          remote: true,
        },
        customConfig: { storage: true },
        checkboxConfig: {
          labelField: "",
          checkAll: false,
          highlight: true,
          trigger: "default",
          reserve: false,
          range: true,
        },
        columnFilter: true,
        rowConfig: {
          keyField: "fillId",
          height: 40,
        },
        imgPreviewVisible: true,
        imgPreviewPath: "",
      },

      // 点击弹出子表单预览
      showSubformDialog: false,
      subformTitle: "",
      subData: [],
      subColumns: [],
      showShadowBox: true, // 滚动条到最底部添加的样式
      splitViewSize: 0,
      isSplitDragging: false, //标记是否拖拽宽度，禁用iframe的事件
      previewFileUrl:''
    }
  },
  props: {
    formOptionData: {
      type: Object,
      default: () => { }
    },
    onHandleImportClick: {
      type: Function
    },
    formAuth: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    isLocalDeploy() {
      return process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test';
    }
  },
  methods: {
    viewData (data) {
      let viewFormJson = {
        fieldList: this.formJson.fieldList.filter(x => x.name == data.name),
        formConfig: this.formJson.formConfig,
        widgetList: this.formJson.widgetList.filter(x => x.options.name == data.name),
      };
      let viewFormData = {
        [data.name]: data.value
      };

      let viewFormOldData = {
        [data.name]: data.oldValue
      };

      this.isShowLogChangeDataView = true;

      this.$nextTick(() => {
        this.$refs.vformChange.setFormJson(viewFormJson);
        this.$refs.vformChangeOld.setFormJson(viewFormJson);

        this.$nextTick(() => {
          this.$refs.vformChange.setFormData(viewFormData);
          this.$refs.vformChangeOld.setFormData(viewFormOldData);

          this.$nextTick(() => {
            this.$refs.vformChange.disableForm();
            this.$refs.vformChangeOld.disableForm();
          })

        })

        this.$forceUpdate();

      })

    },
    showInside (fillId, formId, formJson, data, showHiddenButtons, showFields, formDetail) {
      this.$refs.dlgvFormViewer.showView({
        fillId: fillId,
        formId: formId,
        formJson: formJson,
        formData: data,
        showHiddenWidgets: showHiddenButtons,
        showFields: showFields,
        formDetail: formDetail
      })
      let $this = this;
      this.$nextTick(() => {
        this.$refs.dlgvFormViewer.disableForm()
        // 直接禁用表单时，引用方式的子表单行还没有完全添加上，因此尝试三次执行
        let intervalTime = 300;
        let i = 0
        let tmp = setInterval(() => {
          if (i > 3) {
            clearInterval(tmp)
          } else {
            i++;
            $this.$refs.dlgvFormViewer.disableForm()
          }
        }, intervalTime);
      })

    },
    viewFormItem (item) {
      console.log('viewFormItem', item)
    },
    removeFormItem (item) {
      console.log('removeFormItem', item)
    },
    onHandlePreviewImg () {

    },
    showSubform (field, subformData, column) {
      this.$nextTick(() => {
        const subformWidgets = column.widgetList;
        const columnConfig = filedColumnMap['sub-form'](column);

        this.subformTitle = `【${columnConfig.title}】详细数据`;
        this.subColumns = columnConfig.params.subWidgets;

        this.subData = subformData;

        if (columnConfig.params.showRowNumber) {
          this.tableConfig.showRowNumber = columnConfig.params.showRowNumber;
        }
        if (columnConfig.params.isShowTitleRow) {
          this.tableConfig.showTitleRow = columnConfig.params.isShowTitleRow;
        }
      });
      this.showSubformDialog = true;
      this.$forceUpdate();
    },
    editFillUser () {
      this.userSelectCallback = (selData) => {
        let data = selData[0]
        updateFillUser({
          agencyUserId: data.agencyUserId,
          fillId: this.fillId,
          formId: this.formId,
          number: data.number,
          userId: data.userId,
          userName: data.name
        }).then(() => {
          this.$message.success('更新成功');
          this.fillInfo.fillUserName = data.name;
          this.fillInfo.fillUserAvatarUrl = data.avatarUrl;
          this.$emit("on-save");
        })
      };
      this.$refs.userSelect.showDialog([this.fillInfo.userId], {
        single: true
      });
    },
    onVFormRenderScroll () {
      const container = this.$refs.vformRenderScroller;
      const atBottom =
        container.scrollHeight - container.clientHeight <= container.scrollTop;
      if (atBottom) {
        if (!this.isBottom) {
          // 滚动到最底部时，添加box-shadow
          // container.style.boxShadow = "";
          this.isBottom = true;
          this.showShadowBox = false;
        }
      } else {
        if (this.isBottom) {
          // container.style.boxShadow = "-10px 0 10px rgba(80,80,80,0.3)";
          // 不在最底部时，移除box-shadow
          this.isBottom = false;
          this.showShadowBox = true;
        }
      }
    },
    showAuditLog(){
      this.$refs.auditLogRef.show(this.formId,this.fillId,this.fillInfo.row)
    },
    showPreviewFile(url){
      this.previewFileUrl = url;
      let savedSize = Number(localStorage.getItem('formViewerSplitSize'))
      savedSize=savedSize>70?70:(savedSize||50);
      this.splitViewSize = savedSize;
    },
    closePreview(){
      let savedSize = Number(localStorage.getItem('formViewerSplitSize')) 
      this.previewFileUrl=''
      this.splitViewSize = 0;
      setTimeout(() => {
        this.saveSplitPanel([{size: savedSize}])
      }, 200);
    },
    close(){
      this.closePreview();
      this.isShow=false;
    },
    openPreviewInNewWindow(){
      window.open(this.previewFileUrl)
    },
    startSplitPanel(){
      this.isSplitDragging= true;
    },
    saveSplitPanel(event){
      localStorage.setItem('formViewerSplitSize',event[0].size)
      this.isSplitDragging= false;
    }
    // async getWaterMark(fileName,fieldId,watermark, ossAddress){
    //   return getWaterMarkFile({
    //     fileName: fileName,
    //     agencyId: this.agencyId,
    //     fieldId, watermark,
    //     formId: this.formDetail.formId,
    //     ossAddress,
    //   })
    // }
  }
}
</script>
<style scoped lang="scss">
.container {
  width: 100%;
  height: calc(90vh - 130px);
  display: inline-flex;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}
.is-fullscreen{
  .container{
    height: calc(100vh - 90px);
  }
}

.slot-title {
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .fill-info {
    display: flex;
    justify-content: center;
    align-content: flex-start;
  }
  .fill-info-user {
    margin: 0 5px;
  }
  .fill-info-user-title {
    font-size: 12px;
    line-height: 24px;
  }
  .fill-info-text {
    font-size: 12px;
    height:18px;
    line-height:18px;
  }
}

.left {
  display: inline-flex;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  margin-right: 00px;
  z-index: 800;
  padding-right: 10px;
  flex-direction: column;

  .left-scroll {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    padding-right: 10px;
    display:inline-flex;
    position:relative;
    background-color:white;

    padding: 0 10px;
    box-sizing:border-box;


    // 第一个下一级div

    &>div:first-of-type {
      width:100%;
    }


  }
  .btn-commit-wrapper {
    height: 55px;
    line-height: 55px;
    flex: 0 auto;
    padding-top: 5px;
    margin-top: 10px;
    .btn-commit {
      width: 130px;
    }
  }
  .btn-commit-shadow {
    box-shadow: -15px 5px 15px 0px rgb(0 0 0 / 30%);
  }
}
.right {
  width: 400px;
  height: 100%;
  flex-shrink: 0;
  padding: 10px;
  border: solid 1px #e9ebee;
  box-sizing: border-box;
  margin-left: 10px;
  display: inline-block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 100000;
  background-color: #f9fafc;
  transition: 0.3s;
  box-shadow: -4px 0px 5px #e9ebee;

  &.shrink {
    // width:0px;
    right: -398px;
    box-shadow: -4px 0px 5px transparent;
    .expand-button {
      background-color: #ffffff;

      svg {
        transition: 0.3s;
        transform: rotate(180deg);
      }
    }
  }

  .expand-button {
    position: absolute;
    height: 40px;
    width: 20px;
    line-height: 40px;
    text-align: center;
    border-radius: 5px 0px 0px 5px;
    left: -19px;
    top: calc(50% - 25px);
    border: solid 1px #dee1e7;
    border-right-color: transparent;
    box-shadow: 0px 0px 5px #e9ebee;
    background-color: #f9fafc;
    cursor: pointer;
    &:hover {
      background-color: #f9fafc;
    }
    svg {
      transition: 0.3s;
      transform: rotate(0deg);
    }
  }
}
.log-title {
  width: 200px;
  height: 30px;
  line-height: 30px;
}
.logview-container {
  height: 100%;
  padding: 0px 5px;
  padding-bottom: 30px;
  overflow-y: auto;
}

::v-deep .picture-view {
  width: 80px;
  height: 80px;
  display: inline-block;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border: solid 1px #e8e8e8;
  &.picture-view:hover {
    cursor: pointer;
    border: solid 1px #409eff;
  }
}

// .title{
//     width:100%;
//     height:35px;
//     line-height:35px;
//     padding-right:50px;
//     font-size:18px;
//     display:inline-flex;
//     justify-content: space-between;
//     &-text{

//     }
//     &-button{
//         font-size:14px;

//     }
// }
</style>
<style lang="scss">
.user-card-wrpper {
  display: inline-flex;
  align-items: center;
  margin-right: 15px;
  &:last-of-type {
    margin-right: 0px;
  }
}

.vFormViewerDialog {
  .slot-title + .el-dialog__headerbtn {
    top: 19px;
  }
  .el-dialog__body {
    padding-bottom: 10px;
  }
}
</style>

<style lang="scss" scoped>
@import url('~@/assets/styles/filetype.css');

::v-deep .checkbox-index {
  position: absolute;
  left: 0px;
  display: inline-block;
  width: 100%;
  text-align: center;
  display: inline-block;
}
::v-deep .vxe-cell--checkbox {
  padding-left: 10px;
}

::v-deep
  .vxe-table--render-default
  .is--checked.vxe-cell--checkbox
  .vxe-checkbox--icon {
  color: #3476f0;
}
::v-deep
  .vxe-table--render-default
  .is--checked.vxe-cell--checkbox
  .vxe-checkbox--icon:before {
  border-color: #3476f0;
  background-color: #3476f0;
}
::v-deep .vxe-checkbox--icon {
  top: 0.7em;
}
::v-deep .vxe-cell--checkbox .vxe-checkbox--icon {
  color: transparent;
}

::v-deep .vxe-cell.c--tooltip {
  .vxe-checkbox--icon.vxe-checkbox--indeterminate-icon {
    display: none;
  }

  .is--checked {
    .vxe-checkbox--icon.vxe-checkbox--checked-icon {
      display: inline-block !important;
    }
    .vxe-checkbox--icon.vxe-checkbox--unchecked-icon {
      display: none !important;
    }
    .checkbox-index {
      display: none;
    }
  }

  .vxe-checkbox--icon.vxe-checkbox--unchecked-icon {
    display: none;
  }
  &:hover {
    .checkbox-index {
      display: none;
    }

    .vxe-checkbox--icon.vxe-checkbox--unchecked-icon {
      display: inline-block;
    }
  }
}

.vxe-header--column {
  height: 36px;
  background-color: #f2f5f7;
}

::v-deep .picture-view {
  height: 25px;
  line-height: 25px;
  width: 25px;
  display: inline-block;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  border: solid 1px transparent;
  border: solid 1px #e8e8e8;

  box-sizing: border-box;
  vertical-align: middle;
  &:hover {
    border: solid 1px #c5c5c5;
  }
  &.more {
    border: solid 1px #e8e8e8;
    &:hover {
      background-color: #c5c5c5;
      color: white;
    }
  }
}

::v-deep .audit-sign-view {
  height: 32px;
  line-height: 32px;
  width: 60px;
  display: inline-block;
  background-size: contain;
  background-position: center center;
  cursor: pointer;
  border: solid 1px #e8e8e8;
  background-repeat: no-repeat;
  background-color: white;

  box-sizing: border-box;
  vertical-align: middle;
  &:hover {
    border: solid 1px #c5c5c5;
  }
  &.more {
    border: solid 1px #e8e8e8;
    &:hover {
      background-color: #c5c5c5;
      color: white;
    }
  }
}

::v-deep .pictureListBox {
  width: 950px;
  height: 500px;
  display: inline-block;
  box-sizing: border-box;
}

::v-deep .pictureListContainer {
  margin-left: -5px;
  margin-right: -5px;
  box-sizing: border-box;
  text-align: left;
  overflow-y: auto;
}
::v-deep .pictureListView {
  width: 128px;
  height: 128px;
  text-align: center;
  line-height: 128px;
  display: inline-block;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  margin: 5px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  &:hover {
    border: solid 1px #409eff;
  }
}

::v-deep .fileListView {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border: solid 1px #e8e8e8;
  padding: 0px 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-top: -1px;
  position: relative;
  z-index: 100;
  &:hover {
    color: #3476f0;
    border: solid 1px #3476f0;
    z-index: 101;
  }
  &:last-of-type {
    border-bottom: solid 1px #e8e8e8;
    &:hover {
      color: #3476f0;
      border: solid 1px #3476f0;
    }
  }
  a {
    text-decoration: none;
  }
}

::v-deep .cell-link-view {
  cursor: pointer;
  color: #3476f0;

  height: 40px;
  line-height: 40px;
  width: 25px;
  display: inline-block;
  background-size: cover;
  background-position: center center;
}
::v-deep .el-checkbox__inner {
  cursor: pointer;
}
::v-deep .el-checkbox__inner.checked {
  border: solid 1px #3476f0;
  background-color: #3476f0;
  color: white;
  line-height: 16px;

  &::before {
    content: '';
    position: absolute;
    height: 0.7em;
    width: 0.32em;
    top: 50%;
    left: 50%;
    border: 2px solid #fff;
    border-left: 0;
    border-top: 0;
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

::v-deep .cursor {
  cursor: pointer;
}

::v-deep .cell-link-view {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  .svg-icon {
    font-size: 16px;
  }

  &.cell-link-view-fill-select {
    width: 100%;
  }
}
::v-deep .user-card-wrpper {
  display: inline-flex;
  align-items: center;
  .user-bind {
    font-size: 12px;
    margin-right: 10px;
  }
}
::v-deep .user-card {
  display: inline-flex;
  align-items: center;
  border-radius: 9px;
  // padding-right: 8px;
  // margin-right: 4px;
  font-size: 12px;
  box-sizing: border-box;
  height: 24px;
  line-height: 24px;
  background-color: #ffffff;
  color: #333333;
  cursor: pointer;

  &-avatar {
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin-right: 3px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    background-color: #3476f0;
    background-size: cover;
    background-position: center center;
    color: white;
    position: relative;
  }
}

::v-deep .sysfield {
  color: #3476f0 !important;
}

::v-deep .sysfield-r {
  color: #f56c6c !important;
}
::v-deep .sysfield-y {
  color: #f09f35 !important;
}
::v-deep .sysfield-g {
  color: #67c23a !important;
}

::v-deep .cell-dept {
  .cell-dept-item {
    margin-right: 5px;
    &:nth-last-of-type() {
      margin-right: 0px;
    }
  }
}
</style>
<style lang="scss">
.file-info {
  box-sizing: border-box;
  &-thumb {
    width: 150px;
  }
  &-name {
    min-width: 150px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 5px;
  }
  &-extra {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }
  &-size {
    display: inline-block;
  }
  &-actions {
    display: inline-block;

    .svg-icon {
      padding: 0px;
      font-size: 20px;
      color: #9e9e9e;
      border-radius: 4px;
      cursor: pointer;
      border: solid 1px transparent;

      &:hover {
        color: #3476f0;
        border: solid 1px #e5e5e5;
        background-color: #eeeeee;
      }
    }
  }
}

::v-deep .viewer-container .viewer-list {
  width: 80% !important;
}

/* 筛选图标鼠标移上图片才出现 */

.vxe-cell {
  padding: 0px 5px;
}
.vxe-body--column:not(.col--ellipsis) {
  padding: 0px 5px;
}
.vxe-cell .vxe-cell--title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.vxe-cell--title {
  font-size: 12px;
  color: #999999;
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
}
.custom-sort.is-order {
  // height:22px;
  width: 20px;
  overflow: hidden;
}
.vxe-cell--filter {
  padding-top: 2px;
}
.vxe-cell--filter .vxe-filter--btn,
.custom-sort .iconfont-jyd.jyd-icon-paixu {
  color: #d3d3d3;
  display: inline-block;
  // width: 20px;
  // height:20px;
}

.custom-sort.is-order,
.iconfont-jyd.jyd-icon-sort-descending,
.iconfont-jyd.jyd-icon-sort-ascending {
  color: #d3d3d3;
}

.vxe-header--column:hover {
  background-color: #e9edf0;
}

.vxe-header--column:hover {
  .vxe-cell--filter .vxe-filter--btn {
    color: #888888;
    cursor: pointer;
  }
  .iconfont-jyd {
    color: #888888;
    cursor: pointer;
  }
}

.vxe-table--body-wrapper.body--wrapper {
  // height:auto!important;
}

.toolbar-wrapper {
  display: flex;
  height: 30px;

  justify-content: space-between;
  // justify-content: flex-start;
  align-items: center;
  margin-top: 16px;

  .search-wrapper {
    display: inline-flex;
    height: 30px;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
  }
  .filter-wrapper {
    height: 30px;
    line-height: 30px;
    width: 100%;
    padding-right: 10px;

    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 1;
    // margin-bottom: 2px;
    padding-bottom: 0px;

    &-group {
      display: inline-flex;
      max-width: 50%;
    }

    &-title {
      width: 45px;
      text-align: center;
      flex-shrink: 0;
      display: inline-block;
    }
    &-container {
      width: 100%;
      flex-grow: 1;
      flex-shrink: 1;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;

      display: inline-block;

      &::-webkit-scrollbar {
        /*高宽分别对应横竖滚动条的尺寸*/
        width: 10px;
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #f0f0f0;
        &:hover {
          background: #a8a8a8;
        }
      }
      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        background: #fafafa;
      }
    }
  }

  .filter-tag {
    margin-right: 5px;
    display: inline-flex;
    align-items: center;
    user-select: none;

    // &:last-of-type {
    //   margin-right: 0px;
    // }

    &-content {
      margin-right: 5px;
      max-width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;

      &-label {
        display: inline-block;
        float: left;
        max-width: 85px;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .filter-tag .filter-tag-content + i {
    margin-top: 3px;
    line-height: 17px;
  }
}

.audit-view-remark {
  line-height: 25px;
}
.audit-view-sign {
  padding: 10px 0px;
}

/*滚动条整体部分*/
.mytable-scrollbar ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
/*滚动条的轨道*/
.mytable-scrollbar ::-webkit-scrollbar-track {
  // background-color: #ffffff;
}
/*滚动条里面的小方块，能向上向下移动*/
.mytable-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:active {
  background-color: #787878;
}
/*边角，即两个滚动条的交汇处*/
.mytable-scrollbar ::-webkit-scrollbar-corner {
  background-color: #ffffff;
}

.editable-input {
  line-height: 30px;
  padding: 0px 5px;
  border: 1px solid #d3d3d3;
  outline: none;
  font-size: 14px;
  color: #333;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
}
.close-preview-window{
  position:absolute;
  top: 5px;
  height:30px;
  right:130px;
  z-index:99;
}
.close-preview-button{
  position:absolute;
  height:30px;
  top: 5px;
  right:30px;
  z-index:99;
}
  .splitpanes__splitter{
    background-color: #e7e7e7;
  }
  .splitpanes.default-theme .splitpanes__pane{
    background-color:transparent;
  }
.free{
  .splitpanes__splitter{
    display: none;
  }
}

// 美化滚动条

 /* 美化滚动条 */
        /* 滚动条整体样式 */
        ::-webkit-scrollbar {
            width: 10px; /* 滚动条宽度 */
            height: 10px; /* 滚动条高度 */
        }

        /* 滚动条轨道 */
        ::-webkit-scrollbar-track {
            background: #f1f1f1; /* 轨道背景色 */
            border-radius: 5px; /* 轨道圆角 */
        }

        /* 滚动条滑块 */
        ::-webkit-scrollbar-thumb {
            background: #888; /* 滑块背景色 */
            border-radius: 5px; /* 滑块圆角 */
        }

        /* 滚动条滑块悬停时 */
        ::-webkit-scrollbar-thumb:hover {
            background: #555; /* 滑块悬停时的背景色 */
        }
</style>

