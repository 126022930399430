<template>
  <div>
    <el-tabs>
      <el-tab-pane v-for="(value, key, index) in relativeForms" :key="index" :label="value.name">
        <el-button type="default" v-if="value.auth.currentInsertFillAuth" @click="addFillItem(value.formId,value.relativeInfo )">新增数据</el-button>
        <fill-select-table-view :fields="value.fields.fieldList" :datas="fillDatas(value)" :isAdmin="value.auth.currentQueryFillListAuth"
        :inFillSelect="false"
        :viewItem="(row,index)=>{ viewFillItem(row,index,value) }"
        :editItem="(row,index)=>{ editFillItem(row,index,value) }"
        :removeItem="(row,index)=>{ deleteFillItem(row,index,value) }" :onHandlePreviewImg="onHandlePreviewImg"></fill-select-table-view>
      </el-tab-pane>
    </el-tabs>

    <el-dialog title="新增数据" :visible.sync="addForm.showAddNew" :width="isMobile?'350px':'1220px'" :before-close="closeNewData" :append-to-body="true" :modal-append-to-body="true">
      <template>
        <v-form-render ref="vformRender" :formJson="addForm.formJson" :formData="addForm.formData" :optionData="formOptionData" :previewState="false">
          <template v-slot:myVxeTable="{fields,datas,isAdmin,viewItem,removeItem, disabled}">
            <fill-select-table-view :fields="fields" :datas="datas" :isAdmin="isAdmin" :viewItem="viewItem" :removeItem="removeItem" :onHandlePreviewImg="onHandlePreviewImg" :disabled="disabled"></fill-select-table-view>
          </template>
        </v-form-render>
      </template>
      <div slot="footer" class="dialog-footer">
        <div class="footer-container">
          <div>
          </div>
          <div>
            <el-button size="small" @click="saveNewData">确 定</el-button>
            <el-button size="small" @click="closeNewData">关 闭</el-button>
          </div>
        </div>
      </div>

    </el-dialog>

    <fillSelector ref="fillSelector"></fillSelector>
    <VFormViewer ref="dlgvFormViewer" @on-save="onHandleEditRefreshData"></VFormViewer>
  </div>
</template>

<script>
import { formDetail, httpGetFormFieldV2, getFormFills, formAuth, checkFill, fill,fillDel,relativeAdd,relativeDel } from '@/api/system/form'
import fillSelectTableView from '@/views/system/form/components/fillSelector/tableView.vue'

import { setSessionStorageObj, getSessionStorageObj } from "@/utils/db";
import { deepClone } from '@/utils/deepClone.js'
import { isMobile } from '@/utils/index'
import fillSelector from '@/views/system/form/components/fillSelector'
export default {
  components: {
    fillSelectTableView,
    fillSelector
  },
  props: {
    relatives: {
      type: Array,
      default: () => []
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    // viewItem: {
    //   type: Function,
    //   default: () => { }
    // },
    // editItem: {
    //   type: Function,
    //   default: () => {}
    // },
    // removeItem: {
    //   type: Function,
    //   default: () => { }
    // },
    onHandlePreviewImg: {
      type: Function,
      default: () => { }
    },
    // 数据关联组件里的显示
    inFillSelect: {
      type: Boolean,
      default: true
    },
    formOptionData: {
      type: Object,
      required :true
    }
  },
  data () {
    return {
      relativeForms: {},
      isMobile: isMobile(),
      formId: '',
      relatives: [],
      agencyUser: {},
      relativeInfo: {},
      addRelativeItem: {},
      addForm: {
        formJson: "",
        formData: {},
        optionData: {},
        isReserve: false,
        showAddNew: false
      }
    }
  },
  watch: {
    relatives: {
      handler: function (val, oldVal) {
        if (val) this.loadRelativeForms(val);
      },
      deep: true,
      immediate: true
    }

  },
  computed: {
    formName: function () {
      let names = [];
      Object.keys(this.relativeForms).forEach(key => {
        names.push(this.relativeForms[key].name)
      });
      return names
    }
  },
  mounted () {
    this.agencyUser = getSessionStorageObj('agencyUser')
  },
  methods: {
    // 循环props.relative 读取表单详情和表单数据
    loadRelativeForms (relatives) {
      if (relatives) this.relatives = relatives;
      let forms = {};
      let relativeInfo = {};
      this.relatives.forEach(item => {
        if (!forms[item.sourceFormId]){
          forms[item.sourceFormId] = [];
          relativeInfo[item.sourceFormId] = this.relatives.find(x=>x.sourceFormId==item.sourceFormId);
        }

        const ids = item.sourceFillids.split(',');
        ids.forEach(id => {
          if (id && forms[item.sourceFormId].indexOf(id) == -1)
            forms[item.sourceFormId].push(id);
        })
      });

      Object.keys(forms).forEach(async formId => {
        await this.loadRelativeForm(formId, forms[formId],relativeInfo);
      });
      this.$forceUpdate();
    },
    // 读取表单详情和表单数据
    loadRelativeForm (formId, fillIds,relativeInfo) {
      // 读取表单详情
      return new Promise((resolve, reject) => {

        Promise.all([formDetail(formId), httpGetFormFieldV2(formId), getFormFills(formId, fillIds.toString()), formAuth(formId)]).then(res => {
          this.$set(this.relativeForms, formId, res[0].data)
          this.$set(this.relativeForms[formId], "fields", res[1].data)
          this.$set(this.relativeForms[formId], "fills", res[2].data)
          this.$set(this.relativeForms[formId], "auth", res[3].data)
          this.$set(this.relativeForms[formId], "relativeInfo", relativeInfo[formId])
          console.log('relativeForms', this.relativeForms[formId])
          resolve();
        });


      })

    },
    fillDatas: function (value) {
      const datas = value.fills.map(x => { return { ...x, ...x.fillJson } })
      return datas;
    },
    addFillItem (formId, relativeInfo) {
      this.formId = formId;
      this.addRelativeItem = relativeInfo.length>0?relativeInfo[0]:null;

      checkFill(formId).then((response) => {

        if (response.code == 200) {
          httpGetFormFieldV2(formId).then((response) => {
            this.addForm.formJson = response.data
            this.addForm.isReserve = response.data.isReserve
            this.addForm.showAddNew = true;

            this.loadRelativeForms();
          })
        }
        else {
          this.msgError("表单已结束填报或暂不允许填报")
        }
      })
    },
    viewFillItem (row, index, formInfo) {
      console.log('dlgvFormViewer', this.$refs.dlgvFormViewer, this.$refs.dlgvFormViewer.showView)
      this.$refs.dlgvFormViewer.showView({
        fillId: row.fillId,
        formId: formInfo.formId,
        formJson: deepClone(formInfo.fields),
        formData: row,
        showHiddenWidgets: formInfo.userId != this.agencyUser.userId,
        showFields: null,
        enableReadonly: true,
        agencyId: formInfo.agencyId
      })
    },
    editFillItem (row, index, formInfo) {
      console.log('editFillItem', row, index, formInfo)

      this.$refs.dlgvFormViewer.show({
          fillId: row.fillId,
          formId: formInfo.formId, 
          formJson: deepClone(formInfo.fields), 
          formData: row, 
          showHiddenWidgets: formInfo.userId != this.agencyUser.userId, 
          showFields: null, 
          enableReadonly: true,
          agencyId: formInfo.agencyId,
          formMode: 'edit'
        })

    },
    deleteFillItem (row, index, formInfo) {
      const self = this
      this.$confirm('是否确认删除该填报？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async function () {
          await fillDel(row.fillId, formInfo.formId)
        })
        .then(() => {
          self.msgSuccess('删除成功')
          // self.fillGridOptions.columns = []
          self.fillGridOptions.data = []
          self.loadRelativeForms();
        })
        .catch(function () { })
    },
    onHandleEditRefreshData () {

    },
    saveNewData () {
      const _this = this
      if (!_this.$refs.vformRender.isFormDataEdit() && !_this.$refs.vformRender.isFormFillWithDefault()) {
        _this.msgError('请填写完表单信息后再提交')
        return
      }

      const getFormJson = (cb) => {
        _this.$refs.vformRender
          .getFormData()
          .then((fillJson) => {
            const fillJsonCopy = JSON.parse(JSON.stringify(fillJson))
            const param = {
              formId: this.addForm.formJson.formId,
              fillJson: fillJson,
              formAgreementId: null,
              formAgreementSignature: ""
            }
            this.fillJson = fillJsonCopy
            if (cb) cb(param)
          })
          .catch((err) => {
            _this.msgError(err)
          })
      }

      // 检查表单的是否允许填报
      checkFill(this.formId).then((response) => {
        if (response.code == 200) {
          getFormJson((param) => {

            fill(param).then((response) => {
              if (response.code == 200) {
                // _this.$refs.vformRender.resetForm();
                this.showAddNew = false;
                this.loadRelativeForms();

              } else {
                _this.msgError('保存失败')
              }
            })
              .catch((err) => {
                _this.msgError(err)
              })
          })
        }
      })
    },
    closeNewData () {
      this.addForm.showAddNew = false;
    },
     showDataSelect (fillSelectOption, callback) {
      // callback:选择完成后的回调
      this.$refs.fillSelector.show(fillSelectOption, callback)
    },
  }
}
</script>
