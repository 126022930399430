var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "log-item", class: [_vm.item.typeStr] }, [
    _c("div", { staticClass: "log-title" }, [
      _c("div", { staticClass: "log-time" }, [
        _c(
          "span",
          { staticStyle: { display: "inline-block", width: "90px" } },
          [_vm._v(_vm._s(_vm.moment(_vm.item.time).format("MM-DD HH:mm")))]
        ),
        _vm._v(" " + _vm._s(_vm.item.userName) + " "),
      ]),
      _c("div", { staticClass: "log-type" }, [
        _vm._v(_vm._s(_vm.item.typeStr)),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "log-text" },
      [
        _vm.item.typeStr == "提交内容"
          ? [_c("div", { staticClass: "log-content" }, [_vm._v("填报了表单")])]
          : _vm._e(),
        _vm.item.typeStr == "修改内容"
          ? [
              _vm._l(_vm.item.data, function (change, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "log-content" },
                  [
                    _vm.showChangeLogDialog(change.fieldType)
                      ? [
                          _c(
                            "span",
                            { staticStyle: { "font-weight": "bold" } },
                            [_vm._v(_vm._s(change.label))]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "data-view-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.viewData(change)
                                },
                              },
                            },
                            [_vm._v("查看变更")]
                          ),
                        ]
                      : change.label
                      ? [
                          _vm.isRenderField(change.fieldType)
                            ? [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "font-weight": "bold" },
                                      },
                                      [_vm._v(_vm._s(change.label))]
                                    ),
                                    _vm._v(" 由【"),
                                    _c(
                                      "fillSelectCardComponentRender",
                                      _vm._b(
                                        {},
                                        "fillSelectCardComponentRender",
                                        _vm.bindRender(
                                          change.fieldType,
                                          change.oldValue
                                        ),
                                        false
                                      )
                                    ),
                                    _vm._v("】 变更为【"),
                                    _c(
                                      "fillSelectCardComponentRender",
                                      _vm._b(
                                        {},
                                        "fillSelectCardComponentRender",
                                        _vm.bindRender(
                                          change.fieldType,
                                          change.value
                                        ),
                                        false
                                      )
                                    ),
                                    _vm._v("】 "),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "bold" } },
                                  [_vm._v(_vm._s(change.label))]
                                ),
                                _vm._v(
                                  "由【" +
                                    _vm._s(change.oldValue || "空") +
                                    " 】变更为【" +
                                    _vm._s(change.value || "空") +
                                    "】 "
                                ),
                              ],
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              }),
              _vm.item.data.length == 0
                ? _c("div", { staticClass: "log-content" }, [
                    _c("span", [_vm._v("重新保存表单，但未更改任何数据")]),
                  ])
                : _vm._e(),
            ]
          : _vm._e(),
        _vm.item.typeStr == "完成支付"
          ? [
              _c("div", { staticClass: "log-content" }, [
                _vm._v("完成填报支付"),
              ]),
            ]
          : _vm._e(),
        _vm.item.typeStr == "支付已确认"
          ? [
              _c("div", { staticClass: "log-content" }, [
                _vm._v("确认用户完成了支付"),
              ]),
            ]
          : _vm._e(),
        _vm.item.typeStr == "一级审核-通过"
          ? [
              _vm.item.status > 6 && _vm.item.status != 14
                ? _c("div", { staticClass: "log-content" }, [
                    _vm._v("完成一级审核，已提交二级审核"),
                  ])
                : _c("div", { staticClass: "log-content" }, [
                    _vm._v("完成一级审核"),
                  ]),
            ]
          : _vm._e(),
        _vm.item.typeStr == "二级审核-通过"
          ? [
              _vm.item.status > 10 && _vm.item.status != 14
                ? _c("div", { staticClass: "log-content" }, [
                    _vm._v("完成二级审核，已提交三级审核"),
                  ])
                : _c("div", { staticClass: "log-content" }, [
                    _vm._v("完成二级审核"),
                  ]),
            ]
          : _vm._e(),
        _vm.item.typeStr == "三级审核-通过"
          ? [_c("div", { staticClass: "log-content" }, [_vm._v("审核通过")])]
          : _vm._e(),
        _vm.item.typeStr == "一级审核-不通过"
          ? [
              _c("div", { staticClass: "log-content" }, [
                _vm._v("一级审核不通过"),
                _vm.item.data && _vm.item.data.length > 0
                  ? _c("span", [
                      _c("br"),
                      _vm._v("审批意见：" + _vm._s(_vm.item.data[0].text)),
                    ])
                  : _vm._e(),
              ]),
            ]
          : _vm._e(),
        _vm.item.typeStr == "二级审核-不通过"
          ? [
              _c("div", { staticClass: "log-content" }, [
                _vm._v("二级审核不通过"),
                _vm.item.data && _vm.item.data.length > 0
                  ? _c("span", [
                      _c("br"),
                      _vm._v("审批意见：" + _vm._s(_vm.item.data[0].text)),
                    ])
                  : _vm._e(),
              ]),
            ]
          : _vm._e(),
        _vm.item.typeStr == "一级审核-返回修改"
          ? [
              _c("div", { staticClass: "log-content" }, [
                _vm._v("一级审核不通过，已退回修改"),
                _vm.item.data && _vm.item.data.length > 0
                  ? _c("span", [
                      _c("br"),
                      _vm._v("审批意见：" + _vm._s(_vm.item.data[0].text)),
                    ])
                  : _vm._e(),
              ]),
            ]
          : _vm._e(),
        _vm.item.typeStr == "二级审核-返回修改"
          ? [
              _c("div", { staticClass: "log-content" }, [
                _vm._v("二级审核不通过，已退回修改"),
                _vm.item.data && _vm.item.data.length > 0
                  ? _c("span", [
                      _c("br"),
                      _vm._v("审批意见：" + _vm._s(_vm.item.data[0].text)),
                    ])
                  : _vm._e(),
              ]),
            ]
          : _vm._e(),
        _vm.item.typeStr == "三级审核-不通过"
          ? [
              _c("div", { staticClass: "log-content" }, [
                _vm._v("三级审核-不通过"),
                _vm.item.data && _vm.item.data.length > 0
                  ? _c("span", [
                      _c("br"),
                      _vm._v("审批意见：" + _vm._s(_vm.item.data[0].text)),
                    ])
                  : _vm._e(),
              ]),
            ]
          : _vm._e(),
        _vm.item.typeStr == "三级审核-返回修改"
          ? [
              _c("div", { staticClass: "log-content" }, [
                _vm._v("三级审核-返回修改"),
                _vm.item.data && _vm.item.data.length > 0
                  ? _c("span", [
                      _c("br"),
                      _vm._v("审批意见：" + _vm._s(_vm.item.data[0].text)),
                    ])
                  : _vm._e(),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }